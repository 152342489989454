:root {
  --background: #1f0808;
  --transparent: rgba(0, 0, 0, 0);
  --blue10: #004c70;
  --blue0: #005f8c;
  --blue09: #007ab5;
  --blue07: #3393c3;
  --blue05: #66aed2;
  --blue03: #99c9e1;
  --green10: #579771;
  --green0: #6dbd8e;
  --green09: #8acaa4;
  --green07: #98d0af;
  --green05: #a7d7bb;
  --green03: #b6dec6;
  --purple10: #683454;
  --purple0: #954b79;
  --purple09: #ab7094;
  --purple07: #ab829d;
  --purple05: #c2a6b9;
  --purple03: #ceb8c7;
  --red0: #a3002f;
  --red09: #b53357;
  --red07: #c76681;
  --yellow0: #fabd5d;
  --yellow09: #fbcb7d;
  --yellow07: #fcd89d;
  --black: #000000;
  --black09: #3a3a39;
  --black08: #565655;
  --black07: #6e6e6e;
  --black06: #868786;
  --black05: #9c9c9c;
  --black04: #afafaf;
  --black03: #c5c6c6;
  --black02: #d9dada;
  --black01: #ececec;
  --black015: #f2f2f2;
  --white: #ffffff;
}

.main_container {
  display: flex;
  margin-left: 50px;
  padding-top: 50px;
  transition: 300ms ease all;
}
.main_container.active {
  margin-left: 250px;
}
.main_container.user_not_found {
  margin-left: 0;
}
.swiper-button-prev,
.swiper-button-next {
  color: var(--blue09) !important;
}
.box {
  width: 33rem;
}
.header-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid var(--black02);
}
.fa-cog-green {
  color: #579771;
}
.header-title {
  line-height: 1.5;
  font-size: 1.5rem;
  font-weight: 500;
}
.page_container {
  display: flex;
  min-height: calc(100vh - 90px);
  width: 100%;
  flex-direction: column;
  align-items: center;
}
.box {
  margin: 2rem 1rem 0;
  height: fit-content;
  border-radius: 10px;
  box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.1);
  padding: 20px;
  background-color: var(--white);
}
.box-container {
  box-shadow: none;
}
.imgContainer {
  display: flex;
  justify-content: center;
}
.button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: var(--green0);
  color: var(--white);
  padding: 0.4rem;
}
.button-primary {
  width: 100%;
  border-width: 0;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: var(--green0);
  color: var(--white);
  padding: 0.4rem;
}
.button:active {
  background-color: var(--green09);
}
.buttonText {
  display: flex;
  color: var(--white);
}
.logoImage {
  height: 7rem;
  width: 7rem;
}
.avatarImg {
  margin: 5px;
  height: 20%;
  width: 20%;
  border-radius: 50%;
  max-height: 70px;
  max-width: 70px;
  cursor: pointer;
}
.fgPass {
  font-weight: normal !important;
  text-align: right;
}
.navLink {
  cursor: pointer;
  color: var(--blue09);
  font-weight: 500;
}
.navLink:hover {
  color: var(--blue05);
}
.fbBtn {
  padding: 0.5rem;
  color: var(--white);
  background-color: #3b5999 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.appleBtn {
  padding: 0.5rem;
  color: var(--white);
  background-color: var(--black) !important;
  border-radius: 0 !important;
}
.googleBtn {
  padding: 0.5rem;
  color: var(--white);
  background-color: #d54837 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.layout-image {
  display: flex;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.layout-item {
  border-radius: 10px;
  cursor: pointer;
  padding: 0 !important;
}
.selected-layout {
  border: 2px solid var(--green0);
  border-radius: 10px;
}
.number {
  position: absolute;
  color: var(--white);
  font-size: 20px;
  font-weight: 700;
}
.num-top-left {
  top: 15px;
  left: 20px;
}
.num-bottom-left {
  bottom: 15px;
  left: 20px;
}
.num-top-right {
  top: 15px;
  right: 20px;
}
.num-bottom-right {
  bottom: 15px;
  right: 20px;
}
.check {
  position: absolute;
  border: 2px solid var(--white);
  right: -12px;
  top: -12px;
  background-color: var(--green0);
  color: var(--white);
  border-radius: 50%;
  height: fit-content;
  width: fit-content;
  padding: 2px 5px;
}
.check-number {
  right: -10px;
  top: -10px;
  font-size: 14px;
  padding: 3px 10px;
}
.check-container {
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
  align-items: center;
  justify-content: center;
}
.center-check {
  border: 1px solid var(--white);
  background-color: var(--green0);
  color: var(--white);
  border-radius: 50%;
  font-size: 26px;
  height: fit-content;
  width: fit-content;
  padding: 5px 13px;
  align-self: center;
}
.home_container {
  display: flex;
  min-height: calc(100vh - 90px);
  width: 100%;
}
.cardset-image {
  width: 122px;
  border-radius: 10px;
}
.layout-con {
  cursor: pointer;
  border-radius: 10px;
}
.info-icon {
  color: var(--white);
  position: absolute;
  margin: 10px;
  cursor: pointer;
}
.info-icon-profile {
  color: var(--blue07);
  position: absolute;
  right: 20px;
  top: 12px;
  cursor: pointer;
}
.lock-icon {
  color: var(--blue07);
  position: absolute;
  right: 0px;
  margin: 10px;
  cursor: pointer;
}
.layout-container {
  display: flex;
  overflow-x: scroll;
  white-space: nowrap;
}
.res-num-container {
  border-radius: 10px;
  color: var(--white);
  padding: 20px;
  margin: 5px;
  cursor: pointer;
}
.is-num-selected {
  background-color: var(--purple0);
}
.is-num-non-selected {
  background-color: var(--black03);
}
.userNameText {
  color: var(--black);
  text-decoration: none;
}
.userNameText:hover {
  text-decoration: none;
}
.check-ring {
  position: absolute;
  border: 1px solid var(--green0);
  right: 10px;
  top: 5px;
  background-color: var(--white);
  color: var(--green0);
  border-radius: 50%;
  height: 30px;
  width: 30px;
  padding: 2px 5px;
}
.check-ring-icon {
  height: fit-content !important;
  width: fit-content !important;
}
.gradient-container {
  position: absolute;
  background: linear-gradient(-180deg, transparent 50%, rgba(0, 0, 0, 0.5));
  border-radius: 10px;
  width: 100%;
  height: 100%;
  bottom: 0;
  color: var(--white);
  font-size: 20px;
}
.gradient-text {
  position: absolute;
  bottom: 0;
  padding: 7px 15px;
}
.avatarContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  max-height: 180px;
  overflow-y: scroll;
}
.avatarImgInnerCon {
  margin: 5px;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  text-align: center;
  display: flex;
  flex: 1;
}
.dotContainer {
  flex-direction: column;
  align-items: flex-end !important;
  position: relative;
  display: flex;
  flex: 1;
}
.dot {
  position: absolute;
  height: 10px;
  width: 10px;
  border-radius: 100%;
}
.userItemCon {
  border-radius: 100%;
  padding: 2px;
  border-width: 2px;
  border-style: solid;
}
.selected-layout-con {
  cursor: pointer;
  border: 2px solid var(--green0);
  border-radius: 10px;
}
.vote-sm-container {
  color: var(--black07);
  font-size: 12px;
  float: right;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.pointer {
  cursor: pointer;
}
.primary_color {
  color: var(--blue09);
}
@media only screen and (max-width: 1022px) {
  .box {
    max-width: 33rem;
  }
}
@media only screen and (max-width: 767px) {
  .fbBtn,
  .appleBtn,
  .googleBtn {
    border-radius: 5px !important;
    margin-top: 5px;
  }
  .box {
    max-width: 33rem;
  }
  .main_container {
    margin-left: 0;
  }
  .main_container.active {
    margin-left: 0;
  }
  .header-title {
    line-height: 1;
    font-size: 1rem;
    font-weight: 500;
  }
}
@media only screen and (max-width: 540px) {
  .box {
    max-width: 26rem;
  }
}
@media only screen and (max-width: 480px) {
  .box {
    max-width: 20rem;
  }
  .header-title {
    line-height: 0.5;
    font-size: 0.7rem;
    font-weight: 500;
  }
}
@media only screen and (max-width: 320px) {
  .box {
    max-width: 18rem;
  }
}
@media only screen and (max-width: 280px) {
  .box {
    max-width: 15rem;
  }
}
div {
  scrollbar-color: var(--black07) var(--black03);
  scrollbar-width: thin;
}
::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  background: var(--black07);
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--black06);
}
